@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Amita');
@import url('https://fonts.googleapis.com/css?family=Lexend');
@import url('https://fonts.googleapis.com/css?family=Source Serif Pro');
@import url('https://fonts.googleapis.com/css?family=Alata');
@import url('https://fonts.googleapis.com/css?family=Itim');

.App {
	text-align: center;
}

.cssload-container {
	width: 100%;
	height: 69px;
	text-align: center;
	margin: 150px 0px;
}

.cssload-speeding-wheel {
	width: 70px;
	height: 70px;
	margin: 0 auto;
	border: 4px solid rgb(0, 0, 0);
	border-radius: 50%;
	border-left-color: transparent;
	border-right-color: transparent;
	animation: cssload-spin 1025ms infinite linear;
	-o-animation: cssload-spin 1025ms infinite linear;
	-ms-animation: cssload-spin 1025ms infinite linear;
	-webkit-animation: cssload-spin 1025ms infinite linear;
	-moz-animation: cssload-spin 1025ms infinite linear;
}



@keyframes cssload-spin {
	100% {
		transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes cssload-spin {
	100% {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-ms-keyframes cssload-spin {
	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes cssload-spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes cssload-spin {
	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 500px) {
	.cssload-container {
		margin: 60px 0px;
	}
}