.AM {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    box-shadow: 25;
    background-color: white;
    padding: 30px;
    text-align: center;
}

@media only screen and (max-width: 460px) {

    .AM {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 365px;
        box-shadow: 25;
        background-color: white;
        padding: 30px 15px;
        text-align: center;
    }
}