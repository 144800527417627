.gg {
    min-height: 45vh;
    font-family: Lexend;
    background-position: center;
    background-size: cover;
}

@media only screen and (max-width: 460px) {

    .gg {
        min-height: 30vh;
    }
}