.N{
  padding: 0;
}

.logo{
  width: 64px;
}

.link {
    font-weight: 500;
    padding: 20px 20px;
    margin-right: 15px;
    color: gray;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: white;
  }
  .link:hover{
    background-color: #404DB6;
    color: white;
  }

  @media only screen and (max-width: 460px) {
    .N {
      padding: 0px 0px 0px 15px;
    }
    .logo{
      width: 55px;
    }
    }